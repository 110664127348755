import React ,{useState,useEffect} from 'react'
import './auth.css';
import { Link , useNavigate, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
// import logo from '../../images/Ibrahim-logo.png'
import Cookies from 'universal-cookie';
import emailLogo from '../../images/email.svg'

const cookies = new Cookies();
const ForgotPassword = () => {
    const { register, handleSubmit } = useForm();
    const [data, setData] = useState('');
    const navigate = useNavigate();
    const [getItem, setItems] = useState([]);
    const [getError, setError] = useState({})
    const [getAuthError, setAuthError] = useState(false)
    const [getEmail, setEmail] = useState(false);
    const [getForgotPass,setForgotPass] = useState('');

    const onSubmit = (data, e) => {
        // data.image_url = data.image_url[0];
        // setData(data);
        setError({})
        setAuthError(false)
        data = {...data, ...getForgotPass};
        //console.log(data);
        
        http.post('/auth/forgot-password-request',data)
        .then((response)=>{
                if(response.status == 200){
                    setEmail(true)              
                }  
                return
            }).then(()=>{
                setTimeout(()=>{
                    setEmail(false)
                    navigate('/reset-password')
                },2000)  
            })
            .catch((err)=> {
                //console.log(err.response.data.message)
                if(err.response.data.message == "Validation Error."){
                    let errs = err.response.data.data;
                    let keys = Object.keys(errs)
                    //console.log(keys);
                    let errors = {};
                    keys.map((key)=> errors[key] = errs[key][0])
                    //console.info(errors);
                    setError(errors)
                }
                else{
                    setAuthError(true)
                }
            })
        }
        // console.log(getAuthError)
    const onError = (errors, e) => console.log(errors, e);
  
 

    const handleChange = (e) =>{
        let forgotPass = {...getForgotPass};
        forgotPass[e.target.name] = e.target.value
        setForgotPass(forgotPass);
    }

    
    return (
        <>

            {     
                (getEmail == true) ? 
                    (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body >Succesfully Sent Email</Toast.Body>
                        </Toast>
                    )
                :null
            }

            <div className="form-design">
                <Form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                    {/* <img src={logo} style={{marginBottom:"44px"}}/> */}
                    <h2 style={{ margin: "80px", marginBottom: "30px" }}>DigiCruiter</h2>
                    {getAuthError ?
                        <div className="mt-4">
                            <p className="authError">
                                The Email is not Correct
                            </p>
                        </div>
                        :
                        null
                    }
                    <Form.Group className='input_wrapper'>
                        <Form.Control
                            type="email"
                            name="email"
                            className="email"
                            // value={id}
                            placeholder="Enter the Email"
                            onChange={(e) => handleChange(e)}
                            />
                        <div class="input_field_icon">
                            <img alt="content_img" src={emailLogo}/>
                        </div>
                        {getError.email? 
                        <span className="text-danger warn" style={{marginTop:"2px"}}>
                            {getError.email}
                        </span> : null
                        }
                    </Form.Group>


                    <div className="mt-4">
                        <Button className="login_btn" variant="primary" type="submit">
                            Submit
                        </Button>
                        
                        <Link to="/login" className="forgot-password">Back to Login Page</Link>
                    </div>
                </Form>
            </div>
    </>
  )
}

export default ForgotPassword;