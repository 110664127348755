import React, { useState, useEffect } from "react";
import "./jobpost.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import http from "../../http";
import TextLineLoader from "../../components/Loaders/TextLineLoader";
import { BsFillCursorFill } from "react-icons/bs";
import loginCheck from "../../helpers/loginCheck";
import Layout from "../../components/layouts/layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from "ckeditor5-build-full";
import { SelectPicker, DatePicker } from "rsuite";
import ComponentGuard from "../../helpers/ComponentGuard";
import Cookies from "universal-cookie";
const JobCategoryRead = () => {
  const [jobView, setJobView] = useState("");
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  useEffect(() => {
    if (!loginCheck()) {
      // console.log("this works");
      navigate("/");
    }
  });

  useEffect(() => {
    fetchSingleCategoryPost();
  }, []);

  const fetchSingleCategoryPost = () => {
    http
      .get("/api/admin/job-category/view/" + id, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        setJobView(res.data.data);
      });
  };
  const formateDate = (value) => {
    let tempDate = new Date(value).toUTCString().toString();
    let date = tempDate.substring(0, 16);
    var hours = tempDate.substring(17, 19);
    var minutes = tempDate.substring(20, 22);
    // console.log(hours,minutes)
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    // console.log(strTime);
    return date + " " + strTime;
  };
  return (
    <>
      <Layout>
        <div className="read">
          <div className="card-body">
            <div className="col-md-12">
              {jobView === "" ? (
                <>
                  <TextLineLoader />
                  <TextLineLoader />
                  <TextLineLoader />
                </>
              ) : (
                <div className="row">
                  <div className="col-sm-6">
                    <label>Name:</label>
                  </div>
                  <div className="col-sm-6">
                    {jobView.name ? (
                      jobView.name
                    ) : (
                      <Badge bg="warning" text="dark">
                        N/A
                      </Badge>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="mt-4"
            style={{
              textAlign: "center",
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          >
            <Link to="/job-category">
              <Button className="action__btn" variant="info">
                Close
              </Button>
            </Link>
            <Link to={"/job-category/edit/" + jobView.id}>
              <Button
                className="action__btn"
                style={{ backgroundColor: "#10827B" }}
                type="submit"
              >
                Edit
              </Button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default JobCategoryRead;
