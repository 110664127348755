import React ,{useState,useEffect} from 'react'
import './community.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';

const CommunityTypeCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [jobDesignation, setJobDesignation] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [getAllCat, setAllCat] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });


    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
      }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        data = {...data, ...jobDesignation};

        data = removeEmpty(data)
        console.log(data)
         
        setLoader(true)
        http
          .post("/api/admin/community-type-create", data, {
            headers: {
              Authorization: "Bearer " + cookies.get("userAuthToken"),
            },
          })
          .then((res) => {
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(() => {
              navigate("/community-type");
            }, 1000);
          })
          .catch((err) => {
            setLoader(false);
            let errs = err.response.data.data;
            let keys = Object.keys(errs);
            console.log(keys);
            let errors = {};
            keys.map((key) => (errors[key] = errs[key][0]));
            console.info(errors);
            setError(err.response.data);

            setErrorObj(errors);
          });
    }
    const onError = (errors, e) => console.log(errors, e);
    const handleChange = (e) =>{
        let job = {...jobDesignation};
        job[e.target.name] = e.target.value
        setJobDesignation(job);
    }
  return (
    <>
    <Layout>
        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design" style={{ width: "50% "}}>
            <h2 style={{color: "black",textDecoration: "underline"}}>Create Community Type</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                

                <Form.Group>
                <br/>
                    <Form.Label className="form_label">Name</Form.Label>
                    <br/>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}
                    />
                    {getErrorObj.name? 
                    <span className="text-danger">
                      {getErrorObj.name}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/community-type">
                        <Button className="action__btn" variant="info">
                            Close
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}
export default CommunityTypeCreate;
