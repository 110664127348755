import React, { useState, useEffect } from "react";
import "./users.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal, Toast, Badge } from "react-bootstrap";
import http from "../../http";
import { GrStatusInfo, GrView } from "react-icons/gr";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import DataTable from "react-data-table-component";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import Cookies from "universal-cookie";
import { RadioGroup, Radio } from "rsuite";


const CompanyList = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [userList, setUserList] = useState([]);
  const [getDeleteUserList, setDeleteUserList] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUserList, setFilterUserList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState();
  const [getCurrentPage, setCurrentPage] = useState();

  const [approveShow, setApproveShow] = useState(false);
  const [getState, setState] = useState();
  const [getStatus, setStatus] = useState(false);
  const [getStatusMsg, setStatusMsg] = useState();

  const cookies = new Cookies();

  const navigate = useNavigate();
  useEffect(() => {
    if (!loginCheck()) {
      navigate("/");
    }
  });

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  useEffect(() => {
    fetchAllCompanyList(getCurrentPage);
  }, [getDeleteUserList]);

  useEffect(() => {
    let query = search.length > 0 ? `search/${search}` : `admin/all`;

    http
      .get(`/api/admin/recruiter-users`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((response) => {
        // setTotalRows(response.data.data.total);
        //console.log("all job category***", response.data.data)
        setFilterUserList(response.data.data.data);
      });
  }, [search]);

  // console.log(filterJobPost)

  // +++++++++++++++++++
  const fetchAllCompanyList = async (page) => {
    setCurrentPage(page);
    setLoading(true);
    setPages(page);
    const response = await http.get(`/api/admin/company/list`, {
      headers: {
        Authorization: "Bearer " + cookies.get("userAuthToken"),
      },
    });
    // console.log("all job list *************", response.data.data.data)
    setFilterUserList(response.data.data.data);
    setUserList(response.data.data.data);
    setTotalRows(response.data.data.total);
    setLoading(false);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    let query = search.length > 0 ? `search/${search}` : `admin/all`;

    setLoading(true);
    const response = await http.get(
      `/api/admin/recruiter-users/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      }
    );
    // console.log(response.data);

    setUserList(response.data.data.data);
    // console.log("+++",response.data.data.data);
    setFilterUserList(response.data.data.data);
    setPerPage(perPage);
    setLoading(false);
  };
  const handleApproveShow = (id, status) => {
    console.log(status);
    setShow(true);
    setId(id);
    setState(status.toString());
  };

  const activeStatus = (id, val) => {
    var data = {
      verify_status: val,
    };
    console.log(id,data);
    http
      .post("/api/admin/company/status/"+id, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        setStatus(true);
        setStatusMsg(res?.data?.data?.message);
        setTimeout(() => {
          setStatus(false);
        }, 2000);
      });
    handleClose();
  };

  useEffect(() => {
    fetchAllCompanyList(1); // fetch page 1 of users
  }, [getStatus]);

  // +++++++++++++++++++
  const formateDate = (value) => {
    let tempDate = new Date(value).toUTCString().toString();
    let date = tempDate.substring(0, 16);
    var hours = tempDate.substring(17, 19);
    var minutes = tempDate.substring(20, 22);
    // console.log(hours,minutes)
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    // console.log(strTime);
    return date + " " + strTime;
  };

  // console.log(jobPost)

  const columns = [
    {
      name: "#",
      cell: (row, index) => (getCurrentPage - 1) * perPage + (index + 1), //RDT provides index by default
      width: "50px",
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Website",
      selector: (row) => row.website_url,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.verify_status == "Approve" ? (
          <Badge bg="success" text="light">
            {row.verify_status}
          </Badge>
        ) : row.verify_status == "Pending" ? (
          <Badge bg="warning" text="dark">
            {row.verify_status}
          </Badge>
        ) : (
          <Badge bg="danger" text="light">
            {row.verify_status}
          </Badge>
        ),
      sortable: true,
    },

    {
      name: "Count",
      selector: (row) => row.count_by_recruiters_count,
      sortable: true,
    },
    // {
    //     name: "Deadline",
    //     selector: row => (row.deadline) ? formateDate(row.deadline)
    //     :
    //     <Badge bg="warning" text="dark">N/A</Badge>,
    // },
    // {
    //     name: "Image",
    //     selector: row => (row.image_url) ? <img width={50} height={50} src={image+'/'+row.image_url} />
    //     :
    //     <Badge bg="warning" text="dark">N/A</Badge>,
    // },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={"user-list/" + row.id}>
            <Button className="action__btn" variant="info">
              <GrView />
            </Button>
          </Link>

          {/* <Link to={"edit/" + row.id}>
            <Button className="action__btn" variant="primary">
              <AiTwotoneEdit />
            </Button>
          </Link> */}
          <Button
            className="action__btn"
            onClick={() => handleApproveShow(row.id, row.verify_status)}
            variant="danger"
          >
            <GrStatusInfo />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Layout>
        {getDeleteUserList ? (
          <Toast
            bg="success"
            style={{ position: "fixed", top: "30px", right: "0" }}
          >
            <Toast.Body>User Deleted</Toast.Body>
          </Toast>
        ) : null}

        {/* <Link to="create">
                    <Button style={{ backgroundColor: "#10827B"}}>Add Recruiter</Button>
                </Link> */}

        <DataTable
          title="Company List"
          columns={columns}
          data={filterUserList}
          progressPending={loading}
          pagination
          paginationServer
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationTotalRows={totalRows}
          // onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          fixedHeaderScrollHeight="800px"
          highlightOnHover
          // subHeader
          // subHeaderComponent={
          //     <input
          //     type="text"
          //     placeholder="Search Here"
          //     className="search_btn form-control"
          //     value={search}
          //     onChange={(e)=> setSearch(e.target.value)}
          //     />
          // }
          subHeaderAlign="right"
        />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Status ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RadioGroup
              name="radioList"
              inline
              onChange={(value) => setState(value)}
              defaultValue={getState}
            >
              <Radio value="Pending">Pending</Radio>
              <Radio value="Decline">Decline</Radio>
              <Radio value="Approve">Approve</Radio>
            </RadioGroup>
          </Modal.Body>
          <Modal.Footer>
            <Link to="/recruiters">
              <Button
                className="action__btn"
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="action__btn"
                onClick={() => activeStatus(id, getState)}
                variant="success"
              >
                Save
              </Button>
              
            </Link>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};

export default CompanyList;
