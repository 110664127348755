import React from 'react'
import Chart from "react-apexcharts";
import { useState } from 'react'
 
 export const  SplineArea = ()=> {
    const [data, setData] = useState({
        series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
          }],
          options: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: ["2018-09-19T00:00:00.000Z", "2018-09-20T01:30:00.000Z", "2018-09-21T02:30:00.000Z", "2018-09-22T03:30:00.000Z", "2018-09-23T04:30:00.000Z", "2018-09-24T05:30:00.000Z", "2018-09-25T06:30:00.000Z"]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
        
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="area" height={350} />
             </div>
       
   )
 }
 export const DistributedColumns = ()=> {
    const [data, setData] = useState({
      series: [{
        data: [21, 22, 10, 28, 16, 21, 13]
      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        colors: ['rgba(0, 143, 251, 0.85)','rgba(0, 227, 150, 0.85)','rgba(254, 176, 25, 0.85)','rgba(255, 69, 96, 0.85)','rgba(119, 93, 208, 0.85)','rgba(84, 110, 122, 0.85)'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
            'Accounts',
            'Analytics',
            'HR',
            'IT',
            'Marketing',
            'Operations',
            'Other',
          ],
          labels: {
            style: {
              colors: ['rgba(0, 143, 251, 0.85)','rgba(0, 227, 150, 0.85)','rgba(254, 176, 25, 0.85)','rgba(255, 69, 96, 0.85)','rgba(119, 93, 208, 0.85)','rgba(84, 110, 122, 0.85)','rgba(38, 166, 154, 0.85)'],
              fontSize: '12px'
            }
          }
        }
      },
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="bar" height={350} />
             </div>
       
   )
 }
 
 export const SimplePieChart = ()=> {
    const [data, setData] = useState({
      series: [44, 0, 13, 43, 22, 45, 21],
            options: {
              chart: {
                width: 380,
                type: 'pie',
              },
              dataLabels: {
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex]+"-posts"
                },
              },
              labels: [
                'Accounts',
                'Analytics',
                'HR',
                'IT',
                'Marketing',
                'Operations',
                'Other',
              ],
              colors: ['rgba(0, 143, 251, 0.85)','rgba(0, 227, 150, 0.85)','rgba(254, 176, 25, 0.85)','rgba(255, 69, 96, 0.85)','rgba(119, 93, 208, 0.85)','rgba(84, 110, 122, 0.85)','rgba(38, 166, 154, 0.85)'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom',

                  }
                }
              }]
            },
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="pie" height={350} />
             </div>
       
   )
 }
 export const ColumnwithGroupLabel = ()=> {
    const [data, setData] = useState({
      series: [{
        name: "sales",
        data: [{
          x: '2019/01/01',
          y: 400
        }, {
          x: '2019/04/01',
          y: 430
        }, {
          x: '2019/07/01',
          y: 448
        }, {
          x: '2019/10/01',
          y: 470
        }, {
          x: '2020/01/01',
          y: 540
        }, {
          x: '2020/04/01',
          y: 580
        }, {
          x: '2020/07/01',
          y: 690
        }, {
          x: '2020/10/01',
          y: 690
        }]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 380
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: function(val) {
              return val
            }
          },
          group: {
            style: {
              fontSize: '10px',
              fontWeight: 700
            },
            groups: [
              { title: '2019', cols: 4 },
              { title: '2020', cols: 4 }
            ]
          }
        },
        title: {
            text: '',
        },
        tooltip: {
          x: {
            formatter: function(val) {
              return val
            }  
          }
        },
      },
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="bar" height={350} />
             </div>
       
   )
 }
 export const Basic = ()=> {
    const [data, setData] = useState({
      series: [{
        data: [400, 430, 448, 470, 540, 580, 690]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [
            'Accounts',
            'Analytics',
            'HR',
            'IT',
            'Marketing',
            'Operations',
            'Other',
          ],
        }
      },
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="bar" height={350} />
             </div>
       
   )
 }
 export const SimpleDonut = ()=> {
    const [data, setData] = useState({
      series: [44, 55,10],
            options: {
              labels: ["Male", "Female","Others"],

              chart: {
                type: 'donut',
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="donut" height={350} />
             </div>
       
   )
 }
 export const StackedBars100 = ()=> {
    const [data, setData] = useState({

      series: [{
        name: 'Accounts',
        data: [44, 55, 41, 37, 22, 43, 21, 44, 55, 41, 37, 22,]
      }, {
        name: 'Analytics',
        data: [53, 32, 33, 52, 13, 53, 32, 33, 52, 13, 43, 32]
      }, {
        name: 'HR',
        data: [12, 17, 11, 9, 15, 11, 20, 11, 9, 15, 11, 20]
      }, {
        name: 'IT',
        data: [9, 7, 5, 8, 6, 7, 5, 8, 6, 6, 9, 4]
      }, {
        name: 'Operations',
        data: [25, 12, 19, 32, 25, 24, 12, 19, 32, 25, 24, 10]
      }, {
        name: 'Other',
        data: [25, 12, 19, 32, 19, 32, 25, 24, 10, 25, 24, 10]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%'
        },
        colors: ['rgba(0, 143, 251, 0.85)','rgba(0, 227, 150, 0.85)','rgba(254, 176, 25, 0.85)','rgba(255, 69, 96, 0.85)','rgba(119, 93, 208, 0.85)','rgba(84, 110, 122, 0.85)','rgba(38, 166, 154, 0.85)'],

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: ''
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "K"
            }
          }
        },
        fill: {
          opacity: 1
        
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    })
   return (
            <div id="chart">
                 <Chart options={data.options} series={data.series} type="bar" height={350} />
             </div>
       
   )
 }
 