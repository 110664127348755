import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm, setValue } from "react-hook-form";
import { Button, Form, Toast } from "react-bootstrap";

import http from "../../http";
import { BsFillCursorFill } from "react-icons/bs";
import { lang } from "../enum/enum";
import "./jobpost.css";
import TextLineLoader from "../../components/Loaders/TextLineLoader";
import loginCheck from "../../helpers/loginCheck";
import Layout from "../../components/layouts/layout";
import Cookies from "universal-cookie";
import { SelectPicker, DatePicker, DateRangePicker } from "rsuite";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from "ckeditor5-build-full";
import uploadPlugin from "../../components/ImageUploader/uploadPlugin";

const JobCategoryEdit = () => {
  const { id } = useParams();
  const [jobPost, setJobPost] = useState("");
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");
  const [getSuccess, setSuccess] = useState(false);
  const [getError, setError] = useState("");
  const [getErrorObj, setErrorObj] = useState({});
  const [parents, setParents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [getFrom, setFrom] = useState("");
  const [getLangError, setLangError] = useState("");
  const [getLoader, setLoader] = useState(false);
  const cookies = new Cookies();
  const image = process.env.REACT_APP_IMAGE_BASE_URL;
  const {
    allowedMaxDays,
    allowedDays,
    allowedRange,
    beforeToday,
    afterToday,
    combine,
  } = DateRangePicker;

  const navigate = useNavigate();
  useEffect(() => {
    if (!loginCheck()) {
      console.log("this works");
      navigate("/");
    }
  });


//   useEffect(() => {
//     fetchAllParents();
//   }, []);

//   const fetchAllParents = () => {
//     http
//       .get("/admin/job-category/view/", {
//         headers: {
//           Authorization: "Bearer " + cookies.get("userAuthToken"),
//         },
//       })
//       .then((res) => {
//         setParents(res.data.data);
//       });
//   };

//   useEffect(() => {
//     if (jobPost.department_id) getDeptSearch();
//   }, [jobPost]);

//   const getDeptSearch = (val = "") => {
//     let val1 = val.length > 0 ? val : "*";
//     if (val.length > 0) {
//       http
//         .get(`/department/search/${val}`, {
//           headers: {
//             Authorization: "Bearer " + cookies.get("userAuth").token,
//           },
//         })
//         .then((response) => {
//           setDepartments(response.data.data);
//         });
//     } else if (jobPost.department_id) {
//       http
//         .get(`/department/get/${jobPost.department_id}`, {
//           headers: {
//             Authorization: "Bearer " + cookies.get("userAuth").token,
//           },
//         })
//         .then((response) => {
//           setDepartments({ data: [response.data.data] });
//         });
//     } else {
//       http
//         .get(`/department/search/${val1}`, {
//           headers: {
//             Authorization: "Bearer " + cookies.get("userAuth").token,
//           },
//         })
//         .then((response) => {
//           setDepartments({ data: [] });
//         });
//     }
//   };

  useEffect(() => {
    fetchEditJobPost();
  }, []);

  const fetchEditJobPost = () => {
    http
      .get("/api/admin/job-category/view/" + id, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        // if (res.data.data.deadline) {
        //   let fromDate = res.data.data.deadline;
        //   let tempDateFrom = new Date(fromDate).toUTCString().toString();
        //   let dateFrom = tempDateFrom.substring(0, 16);
        //   let timeFrom = tempDateFrom.substring(17, 26);
        //   res.data.data.deadline = formateDate(
        //     dateFrom +
        //       " " +
        //       timeFrom +
        //       " " +
        //       "GMT+0600 (Bangladesh Standard Time)"
        //   );
        //   // console.log(res.data.data.from)
        // }
        //   if(res.data.data.to){
        //     let toDate = res.data.data.to
        //     let tempDateTo = new Date(toDate).toUTCString().toString();
        //     let dateTo =  tempDateTo.substring(0, 16)
        //     let timeTo =  tempDateTo.substring(17, 26)
        //     res.data.data.to = formateDate(dateTo+" "+timeTo+" "+"GMT+0600 (Bangladesh Standard Time)")
        //   }
        // console.table(res.data.data)
        setJobPost(removeEmpty(res.data.data));
      });
  };

  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null && v != "")
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  const onSubmit = (data, e) => {
    setErrorObj({});
    setLangError("");
    
    e.preventDefault()
    data = {...data, ...jobPost }
    // data = removeEmpty(data)
  
    // console.log(data);
    setLoader(true);
    http
      .post("/api/admin/job-category/update/" + id, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        // console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(() => {
          navigate("/job-category");
        }, 500);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(Object.keys(err.response.data.data))
        
          let errs = err.response.data.data;
          let keys = Object.keys(errs);
          // console.log(keys);
          let errors = {};
          keys.map((key) => (errors[key] = errs[key][0]));
          // console.info(errors);
          setError(err.response.data);

          setErrorObj(errors);
        
      });
  };

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) => {
    // console.log(e);
    let job = { ...jobPost };
    job[e.target.name] = e.target.value;
    setJobPost(job);
  };

  const formateDate = (val) => {
    // console.log(val);
    let tempDate = new Date(val).toUTCString().toString();
    let fetchDate = tempDate.substring(5, 7);

    let monthConvert =
      tempDate.substring(8, 11) == "Jan"
        ? "01"
        : tempDate.substring(8, 11) == "Feb"
        ? "02"
        : tempDate.substring(8, 11) == "Mar"
        ? "03"
        : tempDate.substring(8, 11) == "Apr"
        ? "04"
        : tempDate.substring(8, 11) == "May"
        ? "05"
        : tempDate.substring(8, 11) == "Jun"
        ? "06"
        : tempDate.substring(8, 11) == "Jul"
        ? "07"
        : tempDate.substring(8, 11) == "Aug"
        ? "08"
        : tempDate.substring(8, 11) == "Sep"
        ? "09"
        : tempDate.substring(8, 11) == "Oct"
        ? "10"
        : tempDate.substring(8, 11) == "Nov"
        ? "11"
        : tempDate.substring(8, 11) == "Dec"
        ? "12"
        : null;
    // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at "
    let date =
      tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate;
    // console.log(date)

    let time = new Date(val).toLocaleTimeString("en-US", {
      hour12: false,
    });

    var combineDate = date + " " + time;
    // console.log(combineDate);
    return combineDate;
  };

  function handleKeyPress(e) {
    // console.log(e.code)
    if (["Minus", "NumpadSubtract"].includes(e.code)) {
      // console.log(e.code);
      e.target.value = e.target.value.slice(0, -1);
    } else if (/^0/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/^0/, "");
    }
    let intpart = e.target.value.split(".")[0];
    let decimal = e.target.value.split(".")[1].slice(0, 4);
    decimal = decimal.length > 0 ? "." + decimal : "";
    e.target.value = intpart + decimal;
  }

  return (
    <>
      <Layout>
        {getLoader && (
          <div className="preloader">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spin"></span>
              </div>
            </div>
          </div>
        )}

        {jobPost == "" ? (
          <>
            <TextLineLoader />
            <TextLineLoader />
            <TextLineLoader />
          </>
        ) : (
          <div className="form_design" style={{ width: "50% "}}>
            <h2 style={{ color: "black", textDecoration: "underline" }}>
              Edit Job Category
            </h2>
            <br/>
            <br/>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <Form.Label className="form_label">Job Category</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={jobPost.name}
                  placeholder="Edit Category Name"
                  // {...register("title")}
                  onChange={(e) => handleChange(e)}
                />
                {getErrorObj.name ? (
                  <span className="text-danger">{getErrorObj.name}</span>
                ) : null}
              </Form.Group>

              <div className="mt-4">
                <Button className="action__btn" style={{ backgroundColor: "#10827B"}} type="submit">
                  Update
                </Button>
                <Link to="/job-category">
                  <Button className="action__btn" variant="info">
                    Close
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        )}
      </Layout>
    </>
  );
};

export default JobCategoryEdit;
