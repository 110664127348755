import React, { useState, useEffect } from "react";
import "./degree.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import http from "../../http";
import TextLineLoader from "../../components/Loaders/TextLineLoader";
import { BsFillCursorFill } from "react-icons/bs";
import loginCheck from "../../helpers/loginCheck";
import Layout from "../../components/layouts/layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from "ckeditor5-build-full";
import { SelectPicker, DatePicker } from "rsuite";
import ComponentGuard from "../../helpers/ComponentGuard";
import Cookies from "universal-cookie";
const DomainRead = () => {
  const [domainView, setDomainView] = useState("");
  const { id } = useParams();

  const navigate = useNavigate();
  const cookies = new Cookies();
  useEffect(() => {
    if (!loginCheck()) {
      // console.log("this works");
      navigate("/");
    }
  });

  // console.log(jobView);
  useEffect(() => {
    fetchSingleDomainPost();
  }, []);

  const fetchSingleDomainPost = () => {
    http
      .get("/api/admin/job-designation/view/" + id, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        setDomainView(res.data.data);
      });
  };

  return (
    <>
      <Layout>
        <div className="read">
          <div className="card-body">
            <div className="col-md-12">
              {domainView === "" ? (
                <>
                  <TextLineLoader />
                  <TextLineLoader />
                  <TextLineLoader />
                </>
              ) : (
                <div className="row">
                  <div className="col-sm-6">
                    <label>Name:</label>
                  </div>
                  <div className="col-sm-6">
                    {domainView.name ? (
                      domainView.name
                    ) : (
                      <Badge bg="warning" text="dark">
                        N/A
                      </Badge>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="mt-4"
            style={{
              textAlign: "center",
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          >
            <Link to="/job-designation">
              <Button className="action__btn" variant="info">
                Close
              </Button>
            </Link>
            <Link to={"/job-designation/edit/" + domainView.id}>
              <Button
                className="action__btn"
                style={{ backgroundColor: "#10827B" }}
                type="submit"
              >
                Edit
              </Button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DomainRead;
