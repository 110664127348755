import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layouts/layout";
import loginCheck from "../helpers/loginCheck";
import http from "../http";
import Cookies from "universal-cookie";
import {
  Basic,
  ColumnwithGroupLabel,
  DistributedColumns,
  SimpleDonut,
  SimplePieChart,
  SplineArea,
  StackedBars100,
} from "../components/reactChart";
import {
  AiFillSignal,
  AiFillUsb,
  AiOutlineAreaChart,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { FaMoneyCheckAlt, FaPercent } from "react-icons/fa";
import { HiOutlineReceiptTax } from "react-icons/hi";

const Dashboard = () => {
  const [data, setData] = useState("");
  const [date, setDate] = useState(new Date().getFullYear());

  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    // if (!loginCheck()) {
    //     //console.log("this works")
    //     navigate("/")
    // }
    console.log(date);

    http
      .get("/api/admin/dashboard", {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        setData(res.data.message);
        console.clear();
      });
  });

  return (
    <Layout>
      <div className="dashboard_section">
        <h1>Statistics Of Current Year ({date})</h1>
        <div className="conatiner">
          <div className="row my-4">
            <div className="col-md-3">
              <div className="card_container">
                <div className="db_card bg-gradient-red">
                  <div className="card_icon">
                    <ImUsers />
                  </div>
                  <div className="card_content">
                    <span className="card_title">Total Candidates</span>
                    <span className="card_subtitle">500</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card_container">
                <div className="db_card bg-gradient-pink">
                  <div className="card_icon">
                    <AiFillSignal />
                  </div>
                  <div className="card_content">
                    <span className="card_title">Total Recruiter </span>
                    <span className="card_subtitle">60</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="card_container">
                <div className="db_card bg-gradient-blue">
                  <div className="card_icon">
                    <FaMoneyCheckAlt />
                  </div>
                  <div className="card_content">
                    <span className="card_title">Total Job Placement</span>
                    <span className="card_subtitle">200</span>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-3">
              <div className="card_container">
                <div className="db_card bg-gradient-green">
                  <div className="card_icon">
                    <HiOutlineReceiptTax />
                  </div>
                  <div className="card_content">
                    <span className="card_title">Applicant Average Age</span>
                    <span className="card_subtitle">30</span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row my-4">
            <div className="col-12 col-sm-12 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Applicants Received By Industried</h5>
                  <DistributedColumns />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Open Positions By Industries</h5>
                  <SimplePieChart />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12 col-sm-12 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    Applicants Percentage (Gender Wise)
                  </h5>
                  <SimpleDonut />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Industry Wise Monthly Recruited</h5>
                  <StackedBars100 />
                </div>
              </div>
            </div>
          </div>
    {/* table */}
          {/* <div className="row">
            <div className="col-12 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Recent Applied Applicants Details</h5>
                  <table className="table align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted bg-light">
                        <th className="min-w-100px">Registration No.</th>
                        <th className="min-w-100px">Applicant Name</th>
                        <th className="min-w-100px">Email</th>
                        <th className="min-w-100px">Interest Sector</th>
                        <th className="min-w-100px">Current Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            A 45-1467
                          </a>
                        </td>
                        <td className="border-0">
                            <span className="text-dark fw-bolder text-hover-primary d-block mb-1">
                                Karim Hasan
                            </span>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Karimhasan@gmail.com
                          </a>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Process Associate
                          </a>
                        </td>
                        <td className="border-0">
                          <a href="#" className="badge badge-primary fw-bold ">
                            Hired
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            A 45-1467
                          </a>
                        </td>
                        <td className="border-0">
                          <span className="text-dark fw-bolder text-hover-primary d-block mb-1">
                            SHakib Hasan
                          </span>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            SHakibhasan@gmail.com
                          </a>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Executive Officer
                          </a>
                        </td>

                        <td className="border-0">
                          <a href="#" className="badge badge-success fw-bold ">
                            Open to offers
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            A 45-1467
                          </a>
                        </td>
                        <td className="border-0">
                          <span className="text-dark fw-bolder text-hover-primary d-block mb-1">
                            Omor Ali
                          </span>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Omoralihasan@gmail.com
                          </a>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Analyst
                          </a>
                          
                        </td>
                        <td className="border-0">
                          <a href="#" className="badge badge-success fw-bold ">
                            Open to offers
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            A 45-1467
                          </a>
                          
                        </td>
                        <td className="border-0">
                          <span className="text-dark fw-bolder text-hover-primary d-block mb-1">
                            Samir
                          </span>
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Samirhasan@gmail.com
                          </a>
                 
                        </td>
                        <td className="border-0">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary d-block mb-1 "
                          >
                            Developer
                          </a>
                          
                        </td>
                        <td className="border-0">
                          <a href="#" className="badge badge-success fw-bold ">
                            Open to offers
                          </a>
                        </td>
                      </tr>
                    </tbody>
                   
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <SplineArea/> */}
      </div>
    </Layout>
  );
};

export default Dashboard;
