import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./pages/Auth/ForgotPassword.jsx";

import { NavProvider } from "./components/NavContext/NavContext";
import Login from "./pages/Auth/Login";
import Cookies from "universal-cookie";

import Forbidden from "./pages/Forbidden.jsx";

import ResetPassword from "./pages/Auth/ResetPassword.jsx";

import Dashboard from "./pages/Dashboard";
import JobCategory from "./pages/JobCategory/index";
import JobCategoryCreate from "./pages/JobCategory/JobCategoryCreate";
import JobCategoryEdit from "./pages/JobCategory/JobCategoryEdit";
import JobCategoryRead from "./pages/JobCategory/JobCategoryRead";

// import JobDesignation from "./pages/JobDesignation";
// import JobDesignationCreate from "./pages/JobDesignation/JobDesignationCreate";
// import JobDesignationEdit from "./pages/JobDesignation/JobDesignationEdit";
// import JobDesignationRead from "./pages/JobDesignation/JobDesignationRead";

import JobType from "./pages/JobType";
import JobTypeCreate from "./pages/JobType/JobTypeCreate";
import JobTypeEdit from "./pages/JobType/JobTypeEdit";
import JobTypeRead from "./pages/JobType/JobTypeRead";

import WorkModel from "./pages/WorkModel";
import WorkModelCreate from "./pages/WorkModel/WorkModelCreate";
import WorkModelEdit from "./pages/WorkModel/WorkModelEdit";
import WorkModelRead from "./pages/WorkModel/WorkModelRead";

// import Countries from "./pages/Countries";
// import CountryCreate from "./pages/Countries/CountryCreate";
// import CountryRead from "./pages/Countries/CountryRead";
// import CountryEdit from "./pages/Countries/CountryEdit";

import JobSkills from "./pages/JobSkills/index.jsx";
import JobSkillsCreate from "./pages/JobSkills/JobSkillsCreate.jsx";
import JobSkillsEdit from "./pages/JobSkills/JobSkillsEdit.jsx";
import JobSkillsRead from "./pages/JobSkills/JobSkillsRead.jsx";
import RoleList from "./pages/Roles";

import UserList from "./pages/Users/index";
import RecruiterList from "./pages/Recruiter";
import CandidateList from "./pages/Candidate";
import DomainCreate from "./pages/Domain/DomainCreate.jsx";
import DomainRead from "./pages/Domain/DomainRead.jsx";
import DomainEdit from "./pages/Domain/DomainEdit.jsx";
import Domain from "./pages/Domain/index.jsx";
import RecruiterUserList from "./pages/Recruiter/RecruiterUserList.jsx";
import CommunityAdminList from "./pages/CommunityAdminList";
import CommunityAdminCreate from "./pages/CommunityAdminList/CommunityAdminCreate";

import Degree from "./pages/Degree/index.jsx";
import DegreeCreate from "./pages/Degree/DegreeCreate";
import DegreeEdit from "./pages/Degree/DegreeEdit";
import DegreeRead from "./pages/Degree/DegreeRead";

import CommunityType from "./pages/CommunityType";
import CommunityTypeCreate from "./pages/CommunityType/CommunityTypeCreate";
import CommunityTypeEdit from "./pages/CommunityType/CommunityTypeEdit";

const App = () => {
  const cookies = new Cookies();
  const [loggedIn, setLoggedIn] = React.useState(false);

  const loginStatus = cookies.get("userAuthToken") != null;

  if (loggedIn !== loginStatus) {
    setLoggedIn(loginStatus);
  }

  return (
    <NavProvider>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/* <Route path="/job/" element={<Dashboard />} /> */}
            <Route path="/forbidden" element={<Forbidden />} />
            {/* <Route path="/job" element={<Job />} />
              <Route path="/job/create" element={<JobCreate/>} />
              <Route path="/job/edit/:id" element={<JobEdit />} />
              <Route path="/job/read/:id" element={<JobRead />}/> */}

            <Route path="/job-category" element={<JobCategory />} />
            <Route
              path="/job-category/create"
              element={<JobCategoryCreate />}
            />
            <Route
              path="/job-category/read/:id"
              element={<JobCategoryRead />}
            />
            <Route
              path="/job-category/edit/:id"
              element={<JobCategoryEdit />}
            />

            <Route path="/job-skills" element={<JobSkills />} />
            <Route path="/job-skills/create" element={<JobSkillsCreate />} />
            <Route path="/job-skills/read/:id" element={<JobSkillsRead />} />
            <Route path="/job-skills/edit/:id" element={<JobSkillsEdit />} />

            <Route path="/job-designation" element={<Domain />} />
            <Route path="/job-designation/create" element={<DomainCreate />} />
            <Route path="/job-designation/read/:id" element={<DomainRead />} />
            <Route path="/job-designation/edit/:id" element={<DomainEdit />} />

            <Route path="/job-type" element={<JobType />} />
            <Route path="/job-type/create" element={<JobTypeCreate />} />
            <Route path="/job-type/edit/:id" element={<JobTypeEdit />} />
            <Route path="/job-type/read/:id" element={<JobTypeRead />} />

            <Route path="/work-model" element={<WorkModel />} />
            <Route path="work-model/create" element={<WorkModelCreate />} />
            <Route path="/work-model/edit/:id" element={<WorkModelEdit />} />
            <Route path="/work-model/read/:id" element={<WorkModelRead />} />

            <Route path="/degree" element={<Degree />} />
            <Route path="degree/create" element={<DegreeCreate />} />
            <Route path="/degree/edit/:id" element={<DegreeEdit />} />
            <Route path="/degree/read/:id" element={<DegreeRead />} />


            <Route path="/community-type" element={<CommunityType />} />
            <Route path="community-type/create" element={<CommunityTypeCreate />} />
            <Route path="/community-type/edit/:id" element={<CommunityTypeEdit />} />


            {/* <Route path="/countries" element={<Countries />} />
            <Route path="/country-create" element={<CountryCreate />} />
            <Route path="/country/read/:id" element={<CountryEdit />} />
            <Route path="/country/edit/:id" element={<CountryRead />} /> */}

            <Route path="/admin" element={<UserList />} />

            <Route path="/role-list" element={<RoleList />} />

            <Route path="/recruiters" element={<RecruiterList />} />

            <Route path="/candidates" element={<CandidateList />} />
            <Route path="/community-admin" element={<CommunityAdminList />} />
            <Route path="/community-admin/create" element={<CommunityAdminCreate />} />
            <Route path="/recruiters/user-list/:id" element={<RecruiterUserList />} />
          </Routes>
        </div>
      </BrowserRouter>
    </NavProvider>
  );
};
export default App;
