import React,{useState,useEffect } from 'react';
import './users.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';

const CandidateList = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [userList, setUserList] = useState([]);
  const [getDeleteUserList, setDeleteUserList] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUserList, setFilterUserList] = useState([]);

  const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  
  

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/");
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    // const deleteEvent = (id) => 
    // {
    //     //console.log("checking id", id)
    //     http.get('/api/admin/admin-users/destroy/'+id,{
    //       headers:{
    //         "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
    //        } 
    //     }).then((res)=>{
    //         setDeleteUserList(true);
    //         setTimeout(()=>{
    //             setDeleteUserList(false)}
    //         ,1000) 
                
    //     })
    //     handleClose();
    // };

    
    useEffect(()=>{
        fetchAllUserList(getCurrentPage);
    },[getDeleteUserList]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http.get(`/api/admin/candidate-users`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
             } 
          }).then((response)=>{
            // setTotalRows(response.data.data.total);
            //console.log("all job category***", response.data.data)
            setFilterUserList(response.data.data.data);
        })
        
    },[search]);

    // console.log(filterJobPost)

    // +++++++++++++++++++
    const fetchAllUserList = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/api/admin/candidate-users`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
             } 
          });
        // console.log("all job list *************", response.data.data.data)
        setFilterUserList(response.data.data.data)
		setUserList(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

		setLoading(true);
		const response = await http.get(`/api/admin/candidate-users/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
             } 
          });
        // console.log(response.data);
        
		setUserList(response.data.data.data);
        // console.log("+++",response.data.data.data);
        setFilterUserList(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllUserList(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++
    const formateDate = (value) =>{
        let tempDate = new Date(value).toUTCString().toString();
        let date =  tempDate.substring(0, 16)
        var hours = tempDate.substring(17, 19);
        var minutes = tempDate.substring(20, 22);
        // console.log(hours,minutes)
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        // console.log(strTime);
        return date+" "+strTime
    }

   // console.log(jobPost)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Candidate Name",
            selector: row => row.name,
            sortable:true
        },
        {
            name: "Email Address",
            selector: row => row.email,
            sortable:true
        },
        // {
        //     name: "Deadline",
        //     selector: row => (row.deadline) ? formateDate(row.deadline) 
        //     : 
        //     <Badge bg="warning" text="dark">N/A</Badge>,
        // },
        // {
        //     name: "Image",
        //     selector: row => (row.image_url) ? <img width={50} height={50} src={image+'/'+row.image_url} /> 
        //     : 
        //     <Badge bg="warning" text="dark">N/A</Badge>,
        // },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                    
                        <Link to={"read/"+row.id}>
                            <Button className="action__btn" variant="info">
                                <GrView/>
                            </Button>
                        </Link>
                

                    
                        {/* <Link to={"edit/"+row.id}>
                            <Button className="action__btn" variant="primary">
                                <AiTwotoneEdit/>
                            </Button>
                        </Link> */}
                    
                    
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>

            {     
                (getDeleteUserList) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body>User Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            
                {/* <Link to="create">
                    <Button style={{ backgroundColor: "#10827B"}}>Add Candidate</Button>
                </Link> */}
            

            <DataTable 
                title="Candidate List"
                columns={columns} 
                data={filterUserList} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                // subHeader
                // subHeaderComponent={
                //     <input 
                //     type="text" 
                //     placeholder="Search Here" 
                //     className="search_btn form-control"
                //     value={search}
                //     onChange={(e)=> setSearch(e.target.value)}
                //     />
                // }
                subHeaderAlign="right"
            />
            

          
        </Layout>
        </>
    );
};

export default CandidateList;