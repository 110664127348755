import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './jobdesignation.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';

const JobSkillsEdit = () => {
    const {id} = useParams()
    const [jobDesignation, setJobDesignation] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [getAllCat, setAllCat] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);

    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
        fetchAllJobCategory();
    },[]);

    const fetchAllJobCategory = () => {
        http.get('/api/admin/job-skill/all-categories', {
            headers:{
                "Authorization": "Bearer " + cookies.get('userAuthToken'),
            }  
        }).then(res=>{
            setAllCat(res.data.data);
        })
    }

    useEffect(()=>{
      fetchEditJobDesignation();
    },[]);

    const fetchEditJobDesignation = () => {
        http.get('/api/admin/job-skill/view/'+id, {
          headers:{
            "Authorization": "Bearer " + cookies.get('userAuthToken'),
          }  
      }).then(res=>{
            setJobDesignation(removeEmpty(res.data.data));
        })
    }

    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
      setErrorObj({})
      setLangError('')
      delete jobDesignation.job_category
      data = {...data, ...jobDesignation};

      console.log(data)
      setLoader(true)
      http.post('/api/admin/job-skill/update/'+id,data,{
        headers:{
            "Authorization": "Bearer " + cookies.get('userAuthToken'),
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/job-skills");
        },500)
        })
      .catch((err)=> {
        setLoader(false);

          let errs = err.response.data.data;
          let keys = Object.keys(errs)
          console.log(keys);
          let errors = {};
          keys.map((key)=> errors[key] = errs[key][0])
          console.info(errors);
          setError(err.response.data)
          
          setErrorObj(errors)
       
      })
  }

  const onError = (errors, e) => console.log(errors, e);
  const handleChange = (e) =>{
    let job = {...jobDesignation};
    job[e.target.name] = e.target.value
    setJobDesignation(job);
}

// console.log(typeof getAllCat[0]?.id);
// console.log(typeof jobDesignation.job_category_id);
  return (
    <>
    <Layout>

      { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {jobDesignation == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
        <div className="form_design" style={{ width: "50% "}}>
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Job Skill</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <br/>
                <Form.Group>
                <br/>
                    <Form.Label className="form_label">Job Skill</Form.Label>
                    <SelectPicker 
                          defaultValue={parseInt(jobDesignation.job_category_id)}
                          data={getAllCat?.map((item) => ({ label: item.name, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setJobDesignation({...jobDesignation, "job_category_id": value})}
                      />
                      {getErrorObj.job_category_id? 
                      <span className="text-danger">
                        {getErrorObj.job_category_id}
                      </span> : null
                      }
                </Form.Group>

              <Form.Group>
              <br/>
                  <Form.Label className="form_label">Name</Form.Label>
                  <Form.Control
                      type="text"
                      name="name"
                      defaultValue={jobDesignation.name}
                      placeholder="Enter Name"
                    //   {...register("title")}
                    onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.name? 
                  <span className="text-danger">
                    {getErrorObj.name}
                  </span> : null
                  }
              </Form.Group>

              

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/job-skills">
                      <Button className="action__btn" variant="info">
                          Close
                      </Button>
                  </Link>
              </div>
            </Form>
        </div> 
        } 
    </Layout>
    </>
  );
};


export default JobSkillsEdit;