import React, { useState, useEffect } from 'react'
import './communityAdmin.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker, DateRangePicker } from 'rsuite';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CommunityAdminCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data, setData] = useState('');
    const [getError, setError] = useState('');
    const [getErrorObj, setErrorObj] = useState({})
    const [getLangError, setLangError] = useState('');
    const [getLoader, setLoader] = useState(false);
    const [category, setCategory] = useState({
        name: ""
    });

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(() => {
        if (!loginCheck()) {
           // console.log("this works")
            navigate("/");
        }
    });
    const handleChange = (e) => {
        const value = e.target.value;
        setCategory({
            ...category,
            [e.target.name]: value,
        });
    };

    

    const onSubmit = (data,e) => {
        setErrorObj({})
        setLangError('')

        // data = {...data, ...jobCategory};
        // data = removeEmpty(data)


        // data.user_id = cookies.get('userAuthToken').id
        e.preventDefault();

        data = {...data, ...category};
        data.role = "Community Admin"
         

        setLoader(true)

        http.post('/api/admin/community_admin/added', data, {
            headers: {
                "Authorization": "Bearer " + cookies.get('userAuthToken'),
            }
        }
        )
            .then((res) => {
                // console.log("created", res.data.data);
                localStorage.setItem("success", true);
                setTimeout(() => {
                    navigate("/community-admin");
                }, 1000)
            })
            .catch((err) => {
                setLoader(false);
                // console.log(Object.keys(err.response.data.data))
                
                    let errs = err.response.data.data;
                    let keys = Object.keys(errs)
                    // console.log(keys);
                    let errors = {};
                    keys.map((key) => errors[key] = errs[key][0])
                    // console.info(errors);
                    setError(err.response.data)

                    setErrorObj(errors)
                
                
            })
    }
   

    return (
        <>
            <Layout>
                {getLoader &&
                    <div className="preloader">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spin"></span></div>
                        </div>
                    </div>
                }

                <div className="form_design" style={{ width: "50% "}}>
                    <h2 style={{ color: "black", textDecoration: "underline" }}>Create Community Admin</h2>
                    <br/>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                        <br/>
                        <Form.Label className="form_label">Name</Form.Label>
                        <br/>
                        <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                onChange={(e) => handleChange(e)}
                                />
                            {getErrorObj.name ?
                                <span className="text-danger">
                                    {getErrorObj.name}
                                </span> : null
                            }
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="form_label">Email</Form.Label>
                            <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email"
                                    onChange={(e) => handleChange(e)}
                                    />
                                {getErrorObj.email ?
                                    <span className="text-danger">
                                        {getErrorObj.email}
                                    </span> : null
                                }
                        </Form.Group>

                        <Form.Group>
                                
                            <Form.Label className="form_label">Password</Form.Label>
                            <Form.Control
                                type="text"
                                name="password"
                                placeholder="Enter Password"
                                onChange={(e) => handleChange(e)}
                                />
                            {getErrorObj.password ?
                                <span className="text-danger">
                                    {getErrorObj.password}
                                </span> : null
                            }
                        </Form.Group>

                        <div className="mt-4">
                            <Button className="action__btn" style={{ backgroundColor: "#10827B"}} type="submit">
                                Save
                            </Button>
                            <Link to="/community-admin">
                                <Button className="action__btn" variant="info">
                                    Close
                                </Button>
                            </Link>
                        </div>
                    </Form>
                </div>
            </Layout>
        </>
    )
}

export default CommunityAdminCreate