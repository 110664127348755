import React,{useState,useEffect } from 'react';
import './community.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';

const CommunityType = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [jobPost, setJobPost] = useState([]);
  const [getDeleteJobPost, setDeleteJobPost] = useState(false);
  const [search, setSearch] = useState("");
  const [filterJobPost, setFilterJobPost] = useState([]);

  const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(15);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  
  

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/");
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    const deleteEvent = (id) => 
    {
        //console.log("checking id", id)
        http.get('/api/admin/community-type-list/destroy/'+id,{
          headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
           } 
        }).then((res)=>{
            setDeleteJobPost(true);
            setTimeout(()=>{
                setDeleteJobPost(false)}
            ,1000) 
                
        })
        handleClose();
    };

    
    useEffect(()=>{
        fetchAllJobPost(getCurrentPage);
    },[getDeleteJobPost]);

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http
          .get(
            `/api/admin/community-type-list?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,
            {
              headers: {
                Authorization: "Bearer " + cookies.get("userAuthToken"),
              },
            }
          )
          .then((response) => {
            // setTotalRows(response.data.data.total);
            //console.log("all job category***", response.data.data)
            setFilterJobPost(response.data.data.data);
          });
        
    },[search]);

    // console.log(filterJobPost)

    // +++++++++++++++++++
    const fetchAllJobPost = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(
      `/api/admin/community-type-list?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      }
    );
        // console.log("all job list *************", response.data.data.data)
        setFilterJobPost(response.data.data.data)
		setJobPost(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `list`;

		setLoading(true);
		const response = await http.get(
      `/api/admin/community-type-list/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      }
    );
        // console.log(response.data);
        
		setJobPost(response.data.data.data);
        // console.log("+++",response.data.data.data);
        setFilterJobPost(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllJobPost(1); // fetch page 1 of users
		
	}, []);

   
    



    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable:true
        },
    
        // {
        //     name: "Image",
        //     selector: row => (row.image_url) ? <img width={50} height={50} src={image+'/'+row.image_url} /> 
        //     : 
        //     <Badge bg="warning" text="dark">N/A</Badge>,
        // },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                    
                        {/* <Link to={"read/"+row.id}>
                            <Button className="action__btn" variant="info">
                                <GrView/>
                            </Button>
                        </Link> */}
                

                    
                        <Link to={"edit/"+row.id}>
                            <Button className="action__btn" variant="primary">
                                <AiTwotoneEdit/>
                            </Button>
                        </Link>
                    

                    
                        {/* <Button className="action__btn" onClick={() =>handleShow(row.id)} variant="danger">
                            <MdDelete/>
                        </Button> */}
                    
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>

            {     
                (getDeleteJobPost) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body>Domain Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
                }
            
                <Link to="create">
                    <Button style={{ backgroundColor: "#10827B"}}>Add Community Type</Button>
                </Link>
            

            <DataTable 
                title="Community Type"
                columns={columns} 
                data={filterJobPost} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                // subHeader
                // subHeaderComponent={
                //     <input 
                //     type="text" 
                //     placeholder="Search Here" 
                //     className="search_btn form-control"
                //     value={search}
                //     onChange={(e)=> setSearch(e.target.value)}
                //     />
                // }
                subHeaderAlign="right"
            />
            

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>Are You Sure?</Modal.Body> */}
                <Modal.Footer>
                    <Link to="/degree">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteEvent(id)} style={{ backgroundColor: "#10827B"}}>
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </Layout>
        </>
    );
};

export default CommunityType;