import React, { useState, useEffect } from "react";
import "./users.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Modal, Toast, Badge } from "react-bootstrap";
import http from "../../http";
import { GrView } from "react-icons/gr";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import DataTable from "react-data-table-component";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import Cookies from "universal-cookie";

const RecruiterUserList = () => {
  const [show, setShow] = useState(false);
  // const [id, setId] = useState("");
  const [userList, setUserList] = useState([]);
  const [getDeleteUserList, setDeleteUserList] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUserList, setFilterUserList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState();
  const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();

  const navigate = useNavigate();
  let { id } = useParams();

  console.log(id);

  useEffect(() => {
    if (!loginCheck()) {
      navigate("/");
    }
  });

  // const handleClose = () => setShow(false);
  // const handleShow = (id) => {
  //   setShow(true);
  //   setId(id);
  // };

  useEffect(() => {
    fetchAllCompanyList(getCurrentPage);
  }, [getDeleteUserList]);

  useEffect(() => {
    let query = search.length > 0 ? `search/${search}` : `admin/all`;

    http
      .get(`/api/admin/company/get/${id}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((response) => {
        // setTotalRows(response.data.data.total);
        //console.log("all job category***", response.data.data)
        setFilterUserList(response.data.data.data);
      });
  }, [search]);

  // console.log(filterJobPost)

  // +++++++++++++++++++
  const fetchAllCompanyList = async (page) => {
    setCurrentPage(page);
    setLoading(true);
    setPages(page);
    const response = await http.get(`/api/admin/company/get/` + id, {
      headers: {
        Authorization: "Bearer " + cookies.get("userAuthToken"),
      },
    });
    // console.log("all job list *************", response.data.data.data)
    setFilterUserList(response.data.data.data);
    setUserList(response.data.data.data);
    setTotalRows(response.data.data.total);
    setLoading(false);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    let query = search.length > 0 ? `search/${search}` : `admin/all`;

    setLoading(true);
    const response = await http.get(
      `/api/admin/company/get/${id}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      }
    );
    // console.log(response.data);

    setUserList(response.data.data.data);
    // console.log("+++",response.data.data.data);
    setFilterUserList(response.data.data.data);
    setPerPage(perPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllCompanyList(1); // fetch page 1 of users
  }, []);

  // +++++++++++++++++++
  const formateDate = (value) => {
    let tempDate = new Date(value).toUTCString().toString();
    let date = tempDate.substring(0, 16);
    var hours = tempDate.substring(17, 19);
    var minutes = tempDate.substring(20, 22);
    // console.log(hours,minutes)
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    // console.log(strTime);
    return date + " " + strTime;
  };

  // console.log(jobPost)

  const columns = [
    {
      name: "#",
      cell: (row, index) => (getCurrentPage - 1) * perPage + (index + 1), //RDT provides index by default
      width: "50px",
    },
    {
      name: "Name",
      selector: (row) => row.user.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row.contact_number,
      sortable: true,
    },
  ];

  return (
    <>
      <Layout>
        {getDeleteUserList ? (
          <Toast
            bg="success"
            style={{ position: "fixed", top: "30px", right: "0" }}
          >
            <Toast.Body>User Deleted</Toast.Body>
          </Toast>
        ) : null}

        {/* <Link to="create">
                    <Button style={{ backgroundColor: "#10827B"}}>Add Recruiter</Button>
                </Link> */}

        <DataTable
          title="Company List"
          columns={columns}
          data={filterUserList}
          progressPending={loading}
          pagination
          paginationServer
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationTotalRows={totalRows}
          // onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          fixedHeaderScrollHeight="800px"
          highlightOnHover
          // subHeader
          // subHeaderComponent={
          //     <input
          //     type="text"
          //     placeholder="Search Here"
          //     className="search_btn form-control"
          //     value={search}
          //     onChange={(e)=> setSearch(e.target.value)}
          //     />
          // }
          subHeaderAlign="right"
        />
      </Layout>
    </>
  );
};

export default RecruiterUserList;
