import React, { useState, useEffect } from 'react'
import './jobpost.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import { type, subType, level, order, lang, department } from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker, DateRangePicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin';

const JobCategoryCreate = () => {
    // const { register, handleSubmit } = useForm();
    const [data, setData] = useState('');
    const [jobCategory, setJobCategory] = useState('');
    const [getSuccess, setSuccess] = useState(false);
    const [getError, setError] = useState('');
    const [getErrorObj, setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [departments, setdepartments] = useState([]);
    const [getLangError, setLangError] = useState('');
    const [getLoader, setLoader] = useState(false);
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;
    const [category, setCategory] = useState({
        name: ""
    });

    const cookies = new Cookies();

    const navigate = useNavigate();

    useEffect(() => {
        if (!loginCheck()) {
           // console.log("this works")
            navigate("/");
        }
    });
    const handleChange = (e) => {
        const value = e.target.value;
        setCategory({
            ...category,
            [e.target.name]: value,
        });
    };



    // useEffect(()=>{
    //     fetchAllParents();
    //     // fetchAllDepartments();
    // },[]);

    // const fetchAllParents = () => {
    //     http.get('/job/parent', {
    //         headers:{
    //          "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
    //         }  
    //     }).then(res=>{
    //         setParents(res.data.data);
    //     })
    // }

    // const getDeptSearch = (val) =>{
    //     if(val.length > 0){
    //         http.get(`/department/search/${val}`, {
    //             headers:{
    //              "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
    //             }  
    //         }).then((response)=>{
    //             setdepartments(response.data.data.data);
    //         })
    //     }else if(jobPost.department_id){
    //         http.get(`/department/get/${jobPost.department_id}`, {
    //             headers:{
    //              "Authorization" :"Bearer "+ cookies.get('userAuthToken'), 
    //             }  
    //         }).then((response)=>{
    //             setdepartments([response.data.data]);
    //         })
    //     } else {
    //         setdepartments([]);
    //     }
    // }

    // function removeEmpty(obj) {
    //     return Object.fromEntries(
    //       Object.entries(obj)
    //         .filter(([_, v]) => v != null && v != "")
    //         .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    //     );
    // }

    const handleSubmit = (e) => {
        setErrorObj({})
        setLangError('')

        // data = {...data, ...jobCategory};
        // data = removeEmpty(data)


        // data.user_id = cookies.get('userAuthToken').id
        e.preventDefault();
        const userData = {
            name: category.name,
        };

        setLoader(true)

        http.post('/api/admin/job-category/store', userData, {
            headers: {
                "Authorization": "Bearer " + cookies.get('userAuthToken'),
            }
        }
        )
            .then((res) => {
                // console.log("created", res.data.data);
                localStorage.setItem("success", true);
                setTimeout(() => {
                    navigate("/job-category");
                }, 1000)
            })
            .catch((err) => {
                setLoader(false);
                // console.log(Object.keys(err.response.data.data))
                
                    let errs = err.response.data.data;
                    let keys = Object.keys(errs)
                    // console.log(keys);
                    let errors = {};
                    keys.map((key) => errors[key] = errs[key][0])
                    // console.info(errors);
                    setError(err.response.data)

                    setErrorObj(errors)
                
                
            })
    }
   

    return (
        <>
            <Layout>
                {getLoader &&
                    <div className="preloader">
                        <div className="overlay__inner">
                            <div className="overlay__content"><span className="spin"></span></div>
                        </div>
                    </div>
                }

                <div className="form_design" style={{ width: "50% "}}>
                    <h2 style={{ color: "black", textDecoration: "underline" }}>Create Job Category</h2>
                    <br/>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                      <br/>
                            <Form.Label className="form_label">Category Name</Form.Label>
                          <br/>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter Category Name"
                                // {...register("title")}
                                value={category.name}
                                onChange={handleChange}
                            />
                            {getErrorObj.name ?
                                <span className="text-danger">
                                    {getErrorObj.name}
                                </span> : null
                            }
                        </Form.Group>

                        <div className="mt-4">
                            <Button className="action__btn" style={{ backgroundColor: "#10827B"}} type="submit">
                                Save
                            </Button>
                            <Link to="/job-category">
                                <Button className="action__btn" variant="info">
                                    Close
                                </Button>
                            </Link>
                        </div>
                    </Form>
                </div>
            </Layout>
        </>
    )
}

export default JobCategoryCreate